<template>
  <li class="layers__list-items-item">
    <input type="checkbox" v-model="checkedSync" :id="`map-layer${layerIndex}`" @change="toggleLayer">
    <label :for="`map-layer${layerIndex}`">{{ layer.title }}</label>

    <div
      :class="`route-type popup-${popupIndex}`"
      v-if="layer.params"
    >
      <button type="button" @click.prevent="showMorePopup = !showMorePopup" ref="moreButton">
        <img src="/assets/icons/more.svg" alt="More">
      </button>
      <ul
        class="more"
        v-show="showMorePopup"
        ref="moreContent"
        :style="`top: ${top}px; left: ${left}px`"
      >
        <template v-if="layer.params.types && layer.params.types.length">
          <li class="more-item" v-for="(type, index) in layer.params.types" :key="`${layer.type}-${index}`">
            <input type="checkbox" :value="type" v-model="type.checked" :id="`${layer.type}-routeType${index}`" @change="changeLayer">
            <label :for="`${layer.type}-routeType${index}`">{{ type.title }}</label>
          </li>
        </template>
        <template v-else-if="layer.params.groups && layer.params.groups.length">
          <li class="more-item" v-for="(group, index) in layer.params.groups" :key="`${layer.type}-groupType${index}`">
            <span class="more-title">{{ group.name }}</span>
            <div v-for="(type, indexType) in group.types" :key="`${layer.type}-${indexType}`">
              <input type="checkbox" :value="type" v-model="type.checked" :id="`${layer.type}-routeType${index}${indexType}`" @change="changeGroupLayer">
              <label :for="`${layer.type}-routeType${index}${indexType}`">{{ type.title }}</label>
            </div>
          </li>
        </template>
      </ul>
    </div>
  </li>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
export default {
  name: 'layer-params',
  props: {
    layer: {
      type: Object,
      required: true,
    },
    layerIndex: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    showMorePopup: false,
    clickHandler: null,
    popupIndex: (Math.random() * 100).toFixed(0),
    checkedSync: false,
    top: 25,
    left: 0,
  }),
  methods: {
    toggleLayer() {
      if (this.layer.params && this.layer.params.types) {
        this.layer.params.types.forEach(type => {
          type.checked = this.checkedSync;
        });
      }
      this.$emit('change', this.layerIndex, {
        ...this.layer,
        checked: this.checkedSync,
      });
    },
    changeLayer() {
      this.checkedSync = this.layer.params.types.filter(type => type.checked).length > 0;
      this.$emit('change', this.layerIndex, {
        ...this.layer,
        checked: this.checkedSync,
      });
    },
    changeGroupLayer() {
      this.checkedSync = true;
      this.$emit('change', this.layerIndex, {
        ...this.layer,
        checked: this.checkedSync,
      });
    },
  },
  computed: {
    isMoreLeft() {
      if (!this.$refs.moreButton || !this.showMorePopup) {
        return false;
      }

      const { x } = this.$refs.moreButton.getBoundingClientRect();
      const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      const popupWidth = isMobile ? 150 : 300;

      return x + popupWidth >= screenWidth;
    },
  },
  mounted() {
    this.checkedSync = this.layer.checked;
    // const routeTypeElement = this.$element.querySelector('.route-type');
    this.clickHandler = event => {
      if (event.target.closest(`.route-type.popup-${this.popupIndex}`) === null) {
        this.showMorePopup = false;
      }
    };

    document.addEventListener('click', this.clickHandler);
  },
  watch: {
    layer: {
      deep: true,
      handler() {
        this.checkedSync = this.layer.checked;
      },
    },
    showMorePopup() {
      if (!this.showMorePopup) {
        this.top = 25;
        this.left = 0;
      } else {
        this.$nextTick()
          .then(() => {
            const { height, y, width } = this.$refs.moreContent?.getBoundingClientRect() ?? { height: 0, y: 0 };
            const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

            this.top = y + height > screenHeight ? height * -1 : 25;
            this.left = this.isMoreLeft ? width * -1 : 15;
          });
      }
    },
  },
  beforeDestroy() {
    document.removeEventListener('click', this.clickHandler);
  },
};
</script>

<style lang="scss">
  .mobile {
    .route-type ul.more {
      max-width: 150px;

      li.more-item label {
        max-width: 130px;
      }
    }
  }
</style>

<style lang="scss" scoped>
  .route-type {
    margin-left: 5px;
    position: relative;
    display: inline-block;

    button {
      padding: 0;
      width: 10px;
      border: none;
      background: transparent;

      &:focus {
        outline: none;
      }
    }

    .more {
      position: absolute;
      z-index: 5;
      top: 5px;
      left: 15px;
      padding: 10px;
      background-color: #fff;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
      max-width: 300px;

      &-item label {
        max-width: 280px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &-title {
        display: block;
        width: 100%;
        margin-bottom: 3px;
        font-weight: bold;
      }

      li {
        line-height: 18px;
        list-style: none;
        color: #000;

        input[type='checkbox'] {
          display: none;

          &:checked {
            + label {
              &:before {
                background-color: var(--main-color);
              }
            }
          }
        }

        label {
          cursor: pointer;
          margin-bottom: 0;
          position: relative;
          padding-left: 18px;

          &:before {
            position: absolute;
            top: 4px;
            left: 0;
            content: "";
            width: 10px;
            height: 10px;
            outline: 1px solid #FFFFFF;
            border: 1px solid var(--main-color);
          }
        }
      }
    }
  }

  .layers {
    position: absolute;
    top: 30px;
    right: 30px;
    z-index: 1000;

    &__wrapper {
      position: relative;
    }

    &__control {
      font-size: 0;
      width: 50px;
      height: 50px;
      background-color: var(--main-color);
      border-radius: 3px;
      box-shadow: var(--base-shadow);
      padding: 18px;
      cursor: pointer;
    }

    &__list {
      position: absolute;
      top: 0;
      right: 65px;
      width: 250px;
      background: var(--main-color);
      color: #FFFFFF;
      margin-bottom: 0;
      border-radius: 3px;

      &-header {
        padding: 15px;
        border-bottom: 1px solid rgba(255, 255, 255, .25);
        text-transform: uppercase;
        font-weight: 500;
        font-size: 16px;
      }

      &-items {
        padding: 15px;
        margin-bottom: 0;

        &-item {
          list-style: none;
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }

          input[type='checkbox'] {
            display: none;

            &:checked {
              + label {
                &:before {
                  background: #FFFFFF;
                }
              }
            }
          }

          label {
            cursor: pointer;
            margin-bottom: 0;
            position: relative;
            padding-left: 18px;

            &:before {
              position: absolute;
              top: 4px;
              left: 0;
              content: "";
              width: 10px;
              height: 10px;
              outline: 1px solid #FFFFFF;
              border: 1px solid var(--main-color);
            }
          }
        }
      }
    }
  }
</style>
