











































import { computed, defineComponent, Ref } from '@vue/composition-api';
import { IAnalysisAverageForm1ResponseItem, IAnalysisAverageForm1TableRow, IAnalysisAverageRouteType } from '../../types';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import moment from 'moment';

const analysisHelper = createNamespacedHelpers('layers/analysisAverage');

export default defineComponent({
  name: 'AnalysisAverageForm1Table',
  props: ['openSidebar'],
  setup() {
    const keys: Array<keyof IAnalysisAverageForm1TableRow> = ['count_ts', 'length', 'avg_time', 'avg_speed', 'traffic_avg_time', 'traffic_avg_speed', 'min_complete_time', 'max_speed'];
    const headers: {
      [key in keyof IAnalysisAverageForm1TableRow]?: string
    } = {
      count_ts: 'Количество транспорта проехавшего на участке',
      length: 'Длина участка, км',
      avg_time: 'Среднее время прохождения участка',
      avg_speed: 'Средняя скорость на участке, км/ч',
      traffic_avg_time: 'Среднее время в пробках',
      traffic_avg_speed: 'Средняя скорость в пробке, км/ч',
      min_complete_time: 'Минимальное время прохождения участка',
      max_speed: 'Максимальная скорость на участке, км/ч',
    };

    const { selectedForm1 }: {
      selectedForm1: Ref<IAnalysisAverageForm1ResponseItem | null>,
    } = analysisHelper.useGetters(['selectedForm1']) as any;

    const items = computed(() => {
      const answer: {
        [key in keyof IAnalysisAverageForm1TableRow]?: {
          [key: string]: string | number,
        } | string | number
      } = {};

      if (selectedForm1.value) {
        Object.keys(headers).forEach((value: string) => {
          const h = value as keyof IAnalysisAverageForm1TableRow;
          if (selectedForm1.value) {
            const header = h as keyof IAnalysisAverageRouteType;
            const isCount = h === 'count_ts';
            const toFixed = isCount ? 0 : 2;
            answer[h] = Object.values(selectedForm1.value.route_types).reduce((acc, cur) => {
              return {
                ...acc,
                [cur.route_type_name]: cur[header] && !Number.isNaN(Number(cur[header])) ? Number(cur[header]).toFixed(toFixed) : cur[header],
              };
            }, {});
          }
        });
      }

      return answer;
    });
    const isEnabled = computed(() => Object.keys(items.value).length > 0);
    const dynamicWidth = computed(() => {
      return types.value.length;
    });
    const types = computed(() => {
      return selectedForm1.value ? Object.values(selectedForm1.value.route_types).map(rt => rt.route_type_name) : [];
    });
    const currentDate = computed(() => {
      if (!selectedForm1.value) {
        return '';
      }
      if (selectedForm1.value.isGrouped) {
        return `Период: ${selectedForm1.value.hour}`;
      }

      const date = moment(selectedForm1.value.hour);
      const calendar = date.locale('ru').format('DD MMMM YYYY');
      const time = `${date.format('HH:00')} — ${date.add(1, 'hour').format('HH:00')}`;

      return `Период: ${calendar} ${time}`;
    });

    const currentDateColspan = computed(() => {
      return Object.keys(headers).length * dynamicWidth.value;
    });

    return {
      headers,
      items,
      isEnabled,
      keys,
      dynamicWidth,
      types,
      selectedForm1,
      currentDate,
      currentDateColspan,
    };
  },
});
