
















import Vue from 'vue';
import { defineComponent } from '@vue/composition-api';
import PublicTransportRoutes from './publicTransportRoutes.vue';
import PublicTransport from './publicTransport.vue';
import OutfitsPerformance from './outfitsPerformance.vue';
import ChartsPopup from '@/components/map/simcenter/chartsPopup.vue';

export default defineComponent({
  components: { PublicTransportRoutes, PublicTransport, ChartsPopup, OutfitsPerformance },
  props: {
    openSidebar: {
      type: Boolean,
      default: false,
    },
  },
});
