var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items.length > 0)?_c('div',{staticClass:"wrapper-converge",class:{
    'full-width-table': !_vm.openSidebar,
  }},[_c('div',{staticClass:"converge-table-wrapper"},[_c('table',[_c('thead',[_c('tr',[_c('th',{attrs:{"rowspan":"2"}},[_vm._v("№")]),_vm._l((_vm.headers.row1),function(header,index){return _c('th',{key:index,class:{
              'color-cell': index === 'color',
              'header-cell-route': index === 'route',
              'header-cell-transporter': index === 'transporter',
              'header-cell-distance': index === 'distance',
              'header-cell-percent': index === 'percent',
              'header-cell-index': index === 'index',
            },attrs:{"rowspan":index.indexOf('day') === -1 ? 2 : 1,"colspan":index.indexOf('day') === -1 ? 1 : 7,"height":index.indexOf('day') === '' ? 2 : '20px'},on:{"click":function($event){return _vm.sort(index)}}},[_c('div',{staticClass:"header-cell"},[_vm._v(" "+_vm._s(header)+" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(index === _vm.sortKey && _vm.sortOrder),expression:"index === sortKey && sortOrder"}]},[_c('i',{staticClass:"fas fa-arrow-up"})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(index === _vm.sortKey && !_vm.sortOrder),expression:"index === sortKey && !sortOrder"}]},[_c('i',{staticClass:"fas fa-arrow-down"})])])])})],2),_c('tr',_vm._l((_vm.headers.row2),function(header,index){return _c('th',{key:index,class:{
              'day-th': index.indexOf('day') !== -1,
              'color-cell': index === 'color',
              'header-cell-route': index === 'route',
              'header-cell-transporter': index === 'transporter',
              'header-cell-distance': index === 'distance',
              'header-cell-percent': index === 'percent',
            },attrs:{"rowspan":index.indexOf('day') === -1 ? 2 : 1},on:{"click":function($event){return _vm.sort(index)}}},[_vm._v(" "+_vm._s(header)+" ")])}),0)]),_c('tbody',_vm._l((_vm.items),function(item,index){return _c('tr',{key:index,class:{
            'selected-row': item.groupId && item.groupId === _vm.duplicationRouteGroupId,
            'highlighted-row': item.id == _vm.highlightedRoute,
          }},[_c('td',[_vm._v(_vm._s(index + 1))]),_vm._l((_vm.keys),function(key){return _c('td',{key:index + key,class:{
              'color-cell': key === 'color',
              'route-cell': key === 'route',
              'transporter-cell': key === 'transporter',
            }},[(key === 'percent')?[_c('span',{class:{
                  'percent-cell': item.groupId && item.groupId !== _vm.duplicationRouteGroupId,
                },on:{"click":function($event){item.groupId && item.groupId !== _vm.duplicationRouteGroupId ? _vm.convergeByGroup(item.groupId) : function () {}}}},[_vm._v(_vm._s(item[key])+"%")])]:(key === 'route')?[_c('span',{on:{"click":function($event){return _vm.highlightRoute(item.id)}}},[_vm._v(_vm._s(item[key]))])]:(key !== 'color')?[_vm._v(" "+_vm._s(item[key])+" ")]:[_c('color-picker',{attrs:{"id":item.id,"color":item[key]},on:{"change":_vm.changeColor}})]],2)})],2)}),0)])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }