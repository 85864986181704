











































































































import { computed, defineComponent, Ref, ref } from '@vue/composition-api';
import { IAnalysisReportResponse, IAnalysisTableRow } from '../../types';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { ROUTE_NUMBER_REGEX } from '../../constants/Global';

const analysisHelper = createNamespacedHelpers('layers/analysis');

export default defineComponent({
  name: 'AnalysisTable',
  props: ['openSidebar'],
  setup() {
    const availableSorts = ['sortIndex'];
    const sortKey = ref('sortIndex');
    const sortOrder = ref(true);
    const keys: Array<keyof IAnalysisTableRow> = ['name', 'number', 'routes', 'transporter', 'distance', 'day1', 'day2', 'day3', 'day4', 'day5', 'day6', 'day7', 'sum'];
    const headers: {
      row1: {
        [key in keyof IAnalysisTableRow | 'days']?: string
      },
      row2: {
        [key in keyof IAnalysisTableRow]?: string
      },
    } = {
      row1: {
        name: 'Наименование НП',
        number: 'Число проживающих, чел.',
        routes: 'Проходящие маршруты',
        transporter: 'Перевозчик',
        distance: 'Расстояние до маршрутов НП, м.',
        days: 'Режим работы, кол-во рейсов',
        sum: 'Количество используемого транспорта/класс ТС',
      },
      row2: {
        day1: 'Пн',
        day2: 'Вт',
        day3: 'Ср',
        day4: 'Чт',
        day5: 'Пт',
        day6: 'Сб',
        day7: 'Вс',
      },
    };
    const { report, showReport, localityFilter }: {
      report: Ref<IAnalysisReportResponse | null>,
      showReport: Ref<boolean>,
      localityFilter: Ref<Array<number>>,
    } = analysisHelper.useGetters(['report', 'showReport', 'localityFilter']) as any;
    const items = computed(() => {
      const answer: Array<IAnalysisTableRow> = [];

      if (report.value && report.value.municipalities) {
        let showIndex = 0;
        let sortIndex = 0;
        report.value.municipalities.forEach(m => {
          answer.push({
            showIndex,
            sortIndex,
            municipality: m.name,
            name: '',
            number: '',
            transporter: '',
            distance: 0,
            sum: '',
            routes: '',
            localityRowSpan: 1,
          });
          sortIndex++;
          m.localities.forEach(l => {
            l.routes.forEach((r, i, arr) => {
              const days: {
                [key: string]: number,
              } = {};

              r.schedule_days.forEach(day => {
                days[`day${day.day}`] = day.count_trips;
              });

              const routes = ROUTE_NUMBER_REGEX.exec(r.name);
              const countRouteTransports = r.count_route_transport.map(type => (`${type.count} ${type.class_name ? type.class_name : '—'}`)).join(', ');

              answer.push({
                showIndex,
                sortIndex,
                localityRowSpan: i === 0 ? arr.length : 0,
                name: i === 0 ? l.name : '',
                number: i === 0 ? l.population?.toString() : '',
                routes: routes && routes[0] ? routes[0] : '',
                transporter: r.transporter.name,
                distance: r.distance,
                ...days,
                sum: `${countRouteTransports} (${r.count_planned_transport})`,
              });
              sortIndex++;
            });

            showIndex++;
          });
        });
      }

      return answer.sort((a: any, b: any) => {
        const order = sortOrder.value ? 1 : -1;
        return a[sortKey.value] > b[sortKey.value] ? order : -order;
      });
    });
    const totals = computed(() => {
      if (!report.value) {
        return null;
      }

      const days: {
        [key: string]: number,
      } = {};

      report.value.totals.schedule_days.forEach(day => {
        days[`day${day.day}`] = day.count_trips;
      });

      return {
        number: report.value.totals.population,
        routes: `% охваченных транспортом сел | населения: ${report.value.totals.routes_percent_by_locality_count.toFixed(2)} | ${report.value.totals.routes_percent_by_locality_population.toFixed(2)}`,
        ...days,
        sum: `${report.value.totals.count_transports} (${report.value.totals.count_planned_transport})`,
      };
    });

    return {
      headers,
      items,
      keys,
      availableSorts,
      sortOrder,
      sortKey,
      totals,
      showReport,
      localityFilter,
      sort(key: string) {
        if (sortKey.value === key) {
          sortOrder.value = !sortOrder.value;
        } else if (availableSorts.indexOf(key) !== -1) {
          sortKey.value = key;
          sortOrder.value = true;
        }
      },
    };
  },
});
