




import { computed, defineComponent, onBeforeUnmount, onMounted, ref, Ref, nextTick, watch } from '@vue/composition-api';
import L from 'leaflet';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { LayersService } from '../../services/layers-service';
import { MapService } from '../../services/map-service';

const mapHelper = createNamespacedHelpers('map');
const zoneSelectionHelper = createNamespacedHelpers('zone-selection');

export default defineComponent({
  name: 'SelectZone',
  setup(props, { root }) {
    const { layers }: {
      layers: Ref<Array<string>>
    } = mapHelper.useGetters(['layers']) as any;
    const { setRectangle }: {
      setRectangle: (rectangle: any) => {}
    } = zoneSelectionHelper.useActions(['setRectangle']) as any;
    const { rectangle, enabled }: {
      rectangle: Ref<any>,
      enabled: Ref<boolean>,
    } = zoneSelectionHelper.useGetters(['rectangle', 'enabled']) as any;

    const showSelectZone = computed(() => {
      const activeLayers = ['accident'];

      return layers.value.filter(l => activeLayers.indexOf(l) !== -1).length > 0;
    });

    const temp: Ref<any> = ref(null);

    const clear = () => {
      if (!temp.value) {
        return;
      }

      temp.value.remove();
      temp.value = null;
    };

    const init = () => {
      if (enabled.value && showSelectZone.value) {
        temp.value = L.polygon(rectangle.value, {
          color: 'red',
          opacity: 1,
          fillOpacity: 0,
          isSelect: true,
        } as any);
        MapService.instance.map.addLayer(temp.value);
        temp.value.enableEdit();
      }
    };

    watch(showSelectZone, (val) => {
      if (val) {
        init();
      } else if (temp.value) {
        clear();
      }
    });

    watch(rectangle, (val) => {
      LayersService.instance._accidentLayers._renderMarkers();
      LayersService.instance._accidentLayers._renderAccidentConcentrations();
    });

    const getLatLngArray: () => Array<[number, number]> = () => {
      return temp.value ? temp.value.getLatLngs()[0].map((latLng: { lat: number, lng: number }) => {
        return [latLng.lat, latLng.lng];
      }) : [];
    };

    const onCommit = () => {
      nextTick()
        .then(() => {
          setRectangle(getLatLngArray());
        });
    };
    const onEdit = () => {
      nextTick()
        .then(() => {
          setRectangle(getLatLngArray());
        });
    };
    const onNewVertex = (e: any) => {
      const n: number = e.vertex.getLastIndex();
      const isSelect = e.layer.options.isSelect;

      if (isSelect && n === 3 && MapService.instance.map.editTools.drawing()) {
        nextTick()
          .then(() => {
            MapService.instance.map.editTools.commitDrawing();
          });
      } else if (n >= 4 && isSelect) {
        nextTick()
          .then(() => {
            e.vertex.delete();
          });
      }
    };

    let unsub: () => void;

    MapService.instance.subscribe('map-initialized', () => {
      init();

      MapService.instance.map.on('editable:drawing:commit', onCommit);
      MapService.instance.map.on('editable:vertex:new', onNewVertex);
      MapService.instance.map.on('editable:vertex:dragend editable:vertex:deleted', onEdit);

      unsub = root.$store.subscribeAction(action => {
        if (action.type === 'zone-selection/start') {
          if (temp.value) {
            temp.value.remove();
          } else {
            temp.value = MapService.instance.map.editTools.startPolygon(undefined, {
              color: 'red',
              opacity: 1,
              fillOpacity: 0,
              isSelect: true,
            });
          }
        } else if (action.type === 'zone-selection/clear') {
          clear();
        }
      });
    });

    onBeforeUnmount(() => {
      clear();
      if (unsub) {
        unsub();
      }

      MapService.instance.map.off('editable:drawing:commit', onCommit);
      MapService.instance.map.off('editable:vertex:new', onNewVertex);
      MapService.instance.map.off('editable:vertex:dragend editable:vertex:deleted', onEdit);
    });

    return {};
  },
});
