












































import Vue from 'vue';
import { computed, defineComponent, onBeforeUnmount, Ref, watch } from '@vue/composition-api';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { IRouteType, SitCenterTransport } from '../../../types';
import { ACL } from '../../../modules/acl/acl-service';

const sitCenterHelper = createNamespacedHelpers('layers/sitCenter');
const municipalitiesHelper = createNamespacedHelpers('municipalities');
const routeTypeHelper = createNamespacedHelpers('route-type');

export default defineComponent({
  setup() {
    const { routeTypes }: {
      routeTypes: Ref<Array<IRouteType>>,
    } = routeTypeHelper.useGetters(['routeTypes']) as any;
    const { loadTransports, clearTransports }: {
      loadTransports: ({ municipalityId, bg }: { municipalityId: number, bg?: boolean }) => Promise<void>,
      clearTransports: () => Promise<void>,
    } = sitCenterHelper.useActions(['loadTransports', 'clearTransports']) as any;
    const { transports }: {
      transports: Ref<Array<SitCenterTransport>>,
    } = sitCenterHelper.useGetters(['transports']) as any;
    const { current } = municipalitiesHelper.useGetters(['current']);
    const { openChartsPopup }: {
      openChartsPopup: (
        { type, details, routeType } : { type: string, details: string, routeType?: number }
      ) => Promise<void>,
    } = sitCenterHelper.useActions(['openChartsPopup']) as any;

    const init = (clear = true) => {
      if (!acl.value) {
        return;
      }

      if (clear) {
        clearTransports();
      }

      loadTransports({ municipalityId: current.value.id });
    };

    const total = computed(() => {
      return transports.value.reduce((acc, current) => {
        return {
          inline: acc.inline + current.inline_count,
          actual: acc.actual + current.actual_count,
        };
      }, {
        inline: 0,
        actual: 0,
      });
    });

    const percentage = computed(() => {
      return total.value.actual > 0 ? Math.floor((total.value.inline / total.value.actual) * 100) : 100;
    });

    const acl = computed(() => {
      return ACL.can('dashboard.transports');
    });

    watch(current, () => init());
    init();

    const updater = window.setInterval(() => init(false), 1000 * 30);

    onBeforeUnmount(() => {
      window.clearInterval(updater);
    });

    return {
      details(routeType: number, details: string) {
        openChartsPopup({ type: 'transports', details, routeType });
      },
      getIcon(id: number) {
        const type = routeTypes.value.find(t => Number(t.id) === Number(id));

        if (type) {
          return type.image_url;
        }

        return `/assets/icons/bus.svg`;
      },
      getAlt(id: number) {
        const type = routeTypes.value.find(t => Number(t.id) === Number(id));

        if (type) {
          return type.name;
        }

        return '';
      },
      transports,
      total,
      percentage,
      current,
      acl,
    };
  },
});
