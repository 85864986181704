<template>
  <div class="weather-alert" :class="{'open-sidebar': openSidebar}">
    <div class="rounded-controls">
      <button type="button" class="btn-control weather" @click.prevent="showNotice = !showNotice" :style="{ background: weatherData.color }">
        {{ weatherData.type }}
      </button>

      <transition name="fade">
        <div class="notice" v-if="showNotice">
          <div v-for="(notification, index) in weatherData.notifications" class="notice-item" :key="index">
            <h3>{{ notification.title }}</h3>
            <span>Уровень опасности: {{ notification.allert_type }}</span>
            <span>Сообщение: {{ notification.message }}</span>
            <span>Время начала: {{ notification.starttime | dateTime }}</span>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'weather-alert',
  props: {
    openSidebar: {
      type: Boolean,
      required: true,
    },
    alerts: {
      type: Object,
      required: false,
    },
  },
  data: () => ({
    showNotice: false,
  }),
  filters: {
    dateTime: (date) => {
      return moment(date).format('DD-MM-YYYY HH:mm');
    },
  },
  computed: {
    weatherData() {
      const weatherData = {
        type: 0,
        color: '#ffffff',
        notifications: [],
      };

      if (this.alerts !== null) {
        const totalType = this.alerts.notice.reduce((max, item) => {
          return max < item.allert_type ? item.allert_type : max;
        }, 0);

        weatherData.notifications = this.alerts.notice;

        switch (totalType) {
          case 0:
            weatherData.color = '#ffffff';
            weatherData.type = 0;
            break;
          case 1:
            weatherData.color = '#ec0c22';
            weatherData.type = 1;
            break;
          case 2:
            weatherData.color = '#f9910e';
            weatherData.type = 2;
            break;
          case 3:
            weatherData.color = '#dff153';
            weatherData.type = 3;
            break;
          case 4:
            weatherData.color = '#25ce4c';
            weatherData.type = 4;
            break;
        }
      }

      return weatherData;
    },
  },
};
</script>

<style scoped lang="scss">
  .weather-alert {
    position: absolute;
    left: 30px;
    top: 30px;
    transition: var(--animation-time);
    z-index: 998;

    &.open-sidebar {
      left: calc(var(--sidebar-width) + 30px);
    }

    .rounded-controls {
      position: relative;
      padding: 0 10px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      background: transparent;
      border-radius: 8px;

      .btn-control {
        &:focus {
          outline: none;
        }
      }

      .weather {
        border-radius: 50%;
        background-color: #fff;
        width: 30px;
        height: 30px;
        line-height: 1;
      }

      .notice {
        position: absolute;
        top: 0;
        left: 60px;
        padding: 10px;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: var(--base-shadow);
        min-width: 300px;

        @media screen and (max-width: 1024px) {
          top: 146px;
          left: 40px;
        }

        &-item {
          padding: 10px;
          width: 100%;
          display: flex;
          flex-flow: column nowrap;
          align-items: flex-start;
          box-shadow: 0 2px 6px 0 rgba(0,0,0,.2);

          &:not(:last-child) {
            margin-bottom: 15px;
          }
        }
      }
    }

    .fade-enter-active, .fade-leave-active {
      transition: opacity .5s;
    }

    .fade-enter, .fade-leave-to {
      opacity: 0;
    }
  }
</style>
