








































































import { computed, defineComponent, Ref } from '@vue/composition-api';
import moment from 'moment';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { LayersService } from '../../services/layers-service';
import { IAccidentConcentration, IAccidentStatItem, IAccidentTableRow, ICurrentMunicipality } from '../../types';

const accidentHelper = createNamespacedHelpers('accident');
const municipalitiesHelper = createNamespacedHelpers('municipalities');

export default defineComponent({
  name: 'AccidentTable',
  props: ['openSidebar'],
  setup() {
    const keys: Array<keyof IAccidentTableRow> = ['municipality', 'count', 'appgCount', 'death', 'appgDeath', 'injured', 'appgInjured'];
    const headers: {
        [key in keyof IAccidentTableRow]?: string
    } = {
      municipality: 'АВАРИЙНОСТЬ на территории',
      count: 'ДТП',
      appgCount: 'АППГ',
      death: 'Погибло',
      appgDeath: 'АППГ',
      injured: 'Ранено',
      appgInjured: 'АППГ',
    };
    const { statTotal, concentrations, showAccidentConcentrations }: {
      statTotal: Ref<IAccidentStatItem>,
      concentrations: Ref<Array<IAccidentConcentration>>,
      showAccidentConcentrations: Ref<boolean>,
    } = accidentHelper.useGetters(['statTotal', 'concentrations', 'showAccidentConcentrations']) as any;
    const { municipalities }: {
      municipalities: Ref<Array<ICurrentMunicipality>>,
    } = municipalitiesHelper.useGetters(['municipalities']) as any;

    const dateFrom = computed(() => moment(LayersService.instance._accidentLayers.filter.dates[0]));
    const dateTo = computed(() => moment(LayersService.instance._accidentLayers.filter.dates[1]));
    const isSameYear = computed(() => dateFrom.value.year() === dateTo.value.year());

    const appg = (a: number, v: number) => LayersService.instance._accidentLayers.appg(a, v);
    const getColor = (appg: string) => LayersService.instance._accidentLayers.getColor(appg);

    const getPercent = (appg: string, v: number): string => {
      let answer = `${isSameYear.value ? appg : '—'}`;

      if (appg !== '—' && isSameYear.value) {
        answer = `${answer}%`;
      }
      if (isSameYear.value) {
        answer = `${answer} ${v}`;
      }

      return answer;
    };

    const createRow = (item: IAccidentStatItem): IAccidentTableRow => {
      const appgCount = appg(Number(item.appgCount), Number(item.count));
      const appgDeath = appg(Number(item.appgDeath), Number(item.death));
      const appgInjured = appg(Number(item.appgInjured), Number(item.injured));

      return {
        municipality: (item.municipality_id && municipalities.value.find(m => m.id === Number(item.municipality_id))?.name) ?? 'По всем МО',
        count: item.count ?? 0,
        appgCount: getPercent(appgCount, item.appgCount),
        appgCountColor: getColor(appgCount),
        death: item.death ?? 0,
        appgDeath: getPercent(appgDeath, item.appgDeath),
        appgDeathColor: getColor(appgDeath),
        injured: item.injured ?? 0,
        appgInjured: getPercent(appgInjured, item.appgInjured),
        appgInjuredColor: getColor(appgInjured),
      };
    };

    const items = computed(() => {
      const answer: Array<IAccidentTableRow> = [];

      if (Object.keys(statTotal.value).length > 0) {
        answer.push(createRow(statTotal.value));
      }

      return answer;
    });

    const concentrationData = computed(() => {
      let countRed = 0;
      let deathRed = 0;
      let injuredRed = 0;
      let lenRed = 0;

      let countYellow = 0;
      let deathYellow = 0;
      let injuredYellow = 0;
      let lenYellow = 0;

      concentrations.value
        .filter(c => c.type === 'red')
        .forEach(c => {
          countRed += c.count;
          deathRed += c.death;
          injuredRed += c.injured;
          lenRed++;
        });

      concentrations.value
        .filter(c => c.type === 'yellow')
        .forEach(c => {
          countYellow += c.count;
          deathYellow += c.death;
          injuredYellow += c.injured;
          lenYellow++;
        });

      return {
        countRed,
        deathRed,
        injuredRed,
        lenRed,
        countYellow,
        injuredYellow,
        deathYellow,
        lenYellow,
      };
    });

    return {
      concentrations,
      isSameYear,
      headers,
      items,
      keys,
      statTotal,
      concentrationData,
      showConcentrations: showAccidentConcentrations,
    };
  },
});
