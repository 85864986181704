
































import { computed, defineComponent, onBeforeUnmount, Ref, watch } from '@vue/composition-api';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { ACL } from '../../../modules/acl/acl-service';
import { IRouteType, SitCenterOutfit } from '@/types';

const sitCenterHelper = createNamespacedHelpers('layers/sitCenter');
const municipalitiesHelper = createNamespacedHelpers('municipalities');
const routeTypeHelper = createNamespacedHelpers('route-type');

export default defineComponent({
  setup() {
    const { routeTypes }: {
      routeTypes: Ref<Array<IRouteType>>,
    } = routeTypeHelper.useGetters(['routeTypes']) as any;

    const { loadOutfits, clearOutfits }: {
      loadOutfits: (municipalityId: number) => Promise<void>,
      clearOutfits: () => Promise<void>,
    } = sitCenterHelper.useActions(['loadOutfits', 'clearOutfits']) as any;

    const { outfits }: {
      outfits: Ref<Array<SitCenterOutfit>>,
    } = sitCenterHelper.useGetters(['outfits']) as any;
    const { current } = municipalitiesHelper.useGetters(['current']);

    const acl = computed(() => {
      return ACL.can('dashboard.transports');
    });

    const total = computed(() => {
      return outfits.value.reduce((acc, current) => {
        return {
          fact: acc.fact + current.fact_count,
          actual: acc.actual + current.actual_count,
        };
      }, {
        fact: 0,
        actual: 0,
      });
    });

    const percentage = computed(() => {
      return total.value.actual > 0 ? Math.floor((total.value.fact / total.value.actual) * 100) : 100;
    });

    const init = (clear = true) => {
      if (!acl.value) {
        return;
      }

      if (clear) {
        clearOutfits();
      }

      loadOutfits(current.value.id);
    };

    watch(current, () => init());
    init();

    const updater = window.setInterval(() => init(false), 1000 * 45);

    onBeforeUnmount(() => {
      window.clearInterval(updater);
    });

    return {
      acl,
      outfits,
      current,
      percentage,
      total,
      getIcon(id: number) {
        const type = routeTypes.value.find(t => Number(t.id) === Number(id));

        if (type) {
          return type.image_url;
        }

        return `/assets/icons/bus.svg`;
      },
      getAlt(id: number) {
        const type = routeTypes.value.find(t => Number(t.id) === Number(id));

        if (type) {
          return type.name;
        }

        return '';
      },
    };
  },
});
