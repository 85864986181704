var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showReport && _vm.items.length > 0)?_c('div',{staticClass:"wrapper-analysis",class:{
    'full-width-table': !_vm.openSidebar,
  }},[_c('div',{staticClass:"analysis-table-wrapper"},[_c('table',[_c('thead',[_c('tr',[_c('th',{attrs:{"rowspan":"2"}},[_vm._v("№")]),_vm._l((_vm.headers.row1),function(header,index){return _c('th',{key:index,attrs:{"rowspan":index.indexOf('day') === -1 ? 2 : 1,"colspan":index.indexOf('day') === -1 ? 1 : 7,"height":index.indexOf('day') === '' ? 2 : '20px'},on:{"click":function($event){return _vm.sort(index)}}},[_c('div',{staticClass:"header-cell"},[_vm._v(" "+_vm._s(header)+" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(index === _vm.sortKey && _vm.sortOrder),expression:"index === sortKey && sortOrder"}]},[_c('i',{staticClass:"fas fa-arrow-up"})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(index === _vm.sortKey && !_vm.sortOrder),expression:"index === sortKey && !sortOrder"}]},[_c('i',{staticClass:"fas fa-arrow-down"})])])])})],2),_c('tr',_vm._l((_vm.headers.row2),function(header,index){return _c('th',{key:index,class:{
              'day-th': index.indexOf('day') !== -1,
            },attrs:{"rowspan":index.indexOf('day') === -1 ? 2 : 1},on:{"click":function($event){return _vm.sort(index)}}},[_vm._v(" "+_vm._s(header)+" ")])}),0)]),_c('tbody',[_vm._l((_vm.items),function(item,index){return _c('tr',{key:index,class:{
            'selected-row': item.groupId && item.groupId === _vm.duplicationRouteGroupId,
          }},[(!item.hasOwnProperty('municipality'))?[(item.localityRowSpan !== 0)?[_c('td',{attrs:{"rowspan":item.localityRowSpan}},[_vm._v(_vm._s(item.showIndex + 1))]),_vm._l((_vm.keys),function(key){return _c('td',{key:index + key,class:{
                  'color-cell': key === 'color',
                  'transporter-cell': key === 'transporter',
                },attrs:{"rowspan":['name', 'number'].indexOf(key) !== -1 ? item.localityRowSpan : 1}},[_vm._v(" "+_vm._s(item[key])+" ")])})]:_vm._l((_vm.keys.filter(function (k) { return ['name', 'number'].indexOf(k) === -1; })),function(key){return _c('td',{key:index + key,class:{
                  'color-cell': key === 'color',
                  'transporter-cell': key === 'transporter',
                }},[_vm._v(" "+_vm._s(item[key])+" ")])})]:[_c('td',{attrs:{"colspan":_vm.keys.length + 1}},[_vm._v(" "+_vm._s(item.municipality)+" ")])]],2)}),(_vm.totals && _vm.localityFilter.length === 0)?_c('tr',{staticClass:"total-row"},[_c('td',{attrs:{"colspan":"2"}},[_vm._v("Итого")]),_c('td',[_vm._v(_vm._s(_vm.totals.number))]),_c('td',{attrs:{"colspan":"3"}},[_vm._v(_vm._s(_vm.totals.routes))]),_c('td',[_vm._v(_vm._s(_vm.totals.day1))]),_c('td',[_vm._v(_vm._s(_vm.totals.day2))]),_c('td',[_vm._v(_vm._s(_vm.totals.day3))]),_c('td',[_vm._v(_vm._s(_vm.totals.day4))]),_c('td',[_vm._v(_vm._s(_vm.totals.day5))]),_c('td',[_vm._v(_vm._s(_vm.totals.day6))]),_c('td',[_vm._v(_vm._s(_vm.totals.day7))]),_c('td',[_vm._v(_vm._s(_vm.totals.sum))])]):_vm._e()],2)])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }