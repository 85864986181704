<template>
  <div class="map-component" id="map-component">

    <div id="map" class="main-map"></div>
    <select-zone></select-zone>
    <layers></layers>
    <weather-alert
      v-show="showWeatherAlerts"
      :openSidebar="openSidebar"
      :alerts="alerts"
    >
    </weather-alert>
    <sitcenter-dashboard
      v-if="showSitCenter"
      :openSidebar="openSidebar"
    ></sitcenter-dashboard>
    <route-converge-table
      v-if="showRouteConverge && !isMobile"
      :openSidebar="openSidebar"
    ></route-converge-table>
    <accident-table
      v-if="showAccidentTable && !isMobile"
      :openSidebar="openSidebar"
    ></accident-table>
    <analysis-table
      v-if="showAnalysisTable && !isMobile"
      :openSidebar="openSidebar"
    ></analysis-table>
    <analysis-average-form-1-table
      v-if="showAnalysisAverageForm1Table && !isMobile"
      :openSidebar="openSidebar"
    ></analysis-average-form-1-table>
    <analysis-average-form-2-table
      v-if="showAnalysisAverageForm2Table && !isMobile"
      :openSidebar="openSidebar"
    ></analysis-average-form-2-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { MapService } from '@/services/map-service';
import { LayersService } from '@/services/layers-service';

import Layers from '@/components/map/layers';
import WeatherAlert from '@/components/map/weather-alert';
import RouteConvergeTable from '../../components/map/route-converge-table.vue';
import { isMobile } from 'mobile-device-detect';
import AccidentTable from '../../components/map/accident-table.vue';
import AnalysisTable from '../../components/map/analysis-table.vue';
import SelectZone from '../../components/map/select-zone.vue';
import AnalysisAverageForm1Table from '../../components/map/analysis-average-form1-table.vue';
import AnalysisAverageForm2Table from '../../components/map/analysis-average-form2-table.vue';
import SitcenterDashboard from '../../components/map/simcenter/dashboard.vue';

export default {
  name: 'main-map',
  components: {
    WeatherAlert,
    Layers,
    RouteConvergeTable,
    AccidentTable,
    AnalysisTable,
    SelectZone,
    AnalysisAverageForm1Table,
    AnalysisAverageForm2Table,
    SitcenterDashboard,
  },
  props: {
    analysis: {
      required: false,
    },
  },
  data: () => ({
    defaultCoords: [53.647894000835585, 47.92236328125001],
    zoom: 8,
    subscription: null,
    municipality: null,
    initSubscription: null,
    alerts: null,
    isMobile: isMobile,
  }),
  methods: {
    ...mapActions({
      openLayersFunc: 'layer/openLayers',
      disableLayers: 'layer/disableLayers',
      openLocality: 'layer/openLocality',
      setSidebar: 'setSidebar',
      setOpenLayer: 'setOpenLayer',
    }),
    onLayersChange(layers) {
      this.$store.dispatch('map/setLayers', layers);
    },

    onLayersInit() {
      this.onAnalysis();
      LayersService.instance
        ._weatherLayers
        ._alert
        .subscribe(val => {
          this.alerts = val;
        });
    },
    onAnalysis() {
      if (Number(this.analysis) >= 0) {
        const keep = ['municipality', 'road', 'route', 'locality'];
        const toOpen = ['route'];
        let analysisName = false;
        const disable = this.openLayers.filter(l => keep.indexOf(l) === -1);

        switch (Number(this.analysis)) {
          case 0:
            analysisName = 'analysisDouble';
            break;
          case 1:
            analysisName = 'analysis';
            this.openLocality();
            break;
          case 2:
            analysisName = 'analysisAverage';
            break;

          default:
            break;
        }

        toOpen.push(analysisName);

        if (disable.length > 0) {
          this.disableLayers({
            layers: disable,
            toggle: true,
          });
        }

        this.openLayersFunc(toOpen)
          .then(() => {
            this.setSidebar(true);
            this.setOpenLayer(analysisName);
          });
      } else {
        if (this.openLayers.indexOf('analysisDouble') !== -1) {
          LayersService.instance._analysisDoubleLayers.resetMarkers();
          this.disableLayers({
            layers: ['analysisDouble'],
            toggle: true,
          });
        } else if (this.openLayers.indexOf('analysis') !== -1) {
          this.disableLayers({
            layers: ['analysis'],
            toggle: true,
          });
        } else if (this.openLayers.indexOf('analysisAverage') !== -1) {
          this.disableLayers({
            layers: ['analysisAverage'],
            toggle: true,
          });
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      openSidebar: 'openSidebar',
      openLayers: 'map/layers',
      centerSaved: 'map/latlngs',
      zoomSaved: 'map/zoom',
      isSitcenterActive: 'layers/sitCenter/active',
    }),
    showSitCenter() {
      return this.isSitcenterActive && !isMobile;
    },
    showAlert() {
      return this.alerts !== null;
    },
    showWeatherAlerts() {
      return this.openLayers.indexOf('weather') > -1 && this.showAlert;
    },
    showRouteConverge() {
      return this.openLayers.indexOf('route') > -1;
    },
    showAccidentTable() {
      return this.openLayers.indexOf('accident') > -1;
    },
    showAnalysisTable() {
      return this.openLayers.indexOf('analysis') > -1;
    },
    showAnalysisAverageForm1Table() {
      return this.openLayers.indexOf('analysisAverage') > -1;
    },
    showAnalysisAverageForm2Table() {
      return this.openLayers.indexOf('analysisAverage') > -1;
    },
  },
  watch: {
    '$route'(to, from) {
      this.onAnalysis();
    },
  },
  mounted() {
    this.$nextTick(() => {
      MapService.instance.initMap({
        mapElement: 'map',
        center: this.centerSaved,
        zoom: this.zoomSaved,
      });
    });

    if (MapService.instance.map) {
      this.onAnalysis();
    }

    this.subscription = this.onLayersChange.bind(this);
    this.initSubscription = this.onLayersInit.bind(this);

    LayersService.instance
      .subscribe('change-layers', this.subscription);

    LayersService.instance
      .subscribe('init', this.initSubscription);

    this.$store.subscribe((mutation) => {
      if (['toggleMenu', 'openMenu', 'closeMenu'].indexOf(mutation.type) !== -1) {
        // Дождёмся, пока пройдёт анимация
        window.setTimeout(() => {
          MapService.instance.map.invalidateSize();
        }, 350);
      }
    });
  },

  destroyed() {
    MapService.instance.destroy();
    LayersService.instance.unsubscribe('change-layers', this.subscription);
    LayersService.instance.unsubscribe('init', this.initSubscription);
  },
};
</script>

<style lang="scss">
@media print {
  @page {
    size: auto;
  }

  .layers, .leaflet-control-container, .left-sidebar-menu, .app-base .header {
    display: none;
  }

  .app-base div.content.open-menu {
    margin-left: 0;
  }

  #map {
    height: 100vh;
  }

  .wrapper-average-analysis {
    position: relative !important;

    .analysis-table-wrapper {
      max-height: 100% !important;
    }

    .traffic-roads-list {
      max-height: 100% !important;
      overflow-y: hidden !important;
    }
  }
}
.map-component {
  position: relative;
}

.main-map {
  p
  {
    &.mts {
      margin: 9px 0;
    }

    &.accident-info {
      margin: 4px 0;
    }
  }

  .leaflet-popup-content {
    border: none;
  }

  .road-layer-legend {
    &-wrapper {
      background-color: white;
      padding: 10px;
    }

    &-line {
      width: 40px;
      height: 5px;
      display: block;
      margin-right: 10px;
    }

    &-item {
      display: flex;
      align-items: center;
    }
  }

  .route-number-tooltip {
    background: transparent;
    border: none;
    box-shadow: none;
    font-size: 14px;
    font-weight: bold;

    &::before {
      content: none;
      border: none;
    }
  }

  .leaflet-top {
    top: 95px;

    &.leaflet-right {
      right: 30px;
    }

    .leaflet-control {
      margin: 0;

      display: flex;
      flex-wrap: nowrap;
      align-items: flex-end;
      flex-direction: column;
    }
  }

  .marker-icon {
    width: 47px;
    height: 47px;
    background: #ffffff;
    border-radius: 23px;
    border: 2px solid #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9px 7px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transform: translate3d(-11px, 0, 0px);
    transform: translate3d(-11px, 0, 0px);

    img.invert {
      filter: invert(1);
    }

    &.no-icon {
      position: relative;
      z-index: 100;
      font-weight: 500;
      border-color: rgba(0, 31, 145, 0.35);
      background: rgba(255, 255, 255, 0.7);

      &:before {
        position: absolute;
        z-index: 0;
        content: '';
        top: 2px;
        left: -6px;
        width: 90%;
        height: 90%;
        background-image: url('/assets/icons/temperature.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
      }

      &:after {
        position: absolute;
        content: 'C°';
        right: 1px;
        bottom: 4px;
      }

      span {
        position: absolute;
        top: 5px;
        left: 23px;
        font-size: 15px;
        font-weight: 500;
      }
    }

    &.yandex-icon {

      &:before {
        background-image: none;
      }

      img {
        position: absolute;
        top: 10px;
        left: 0;
        width: 27px;
        height: 27px;
      }
    }
  }

  .marker-cluster-custom {
    background-color: #4287f5;

    div {
      background-color: #194182;
      color: #ffffff;
    }
  }

  .leaflet-popup-content {

    .traffic-light {
      margin: 0;
    }

    .m5-img {
      width: 300px;
      height: 200px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    img.popup-route-icon {
      width: 17px;
      margin-left: 5px;
    }
  }

  table {
    td {
      border: 1px solid var(--border-color);
    }
  }

  .route-number {
    &-icon {
      width: 35px;
    }
    &-text {
      position: relative;
      top: -30px;
      left: 5px;
      color: #ffffff;
      background-color: rgba(0, 11, 109, 0.72);
      border-radius: 50%;
      width: 25px;
      height: 25px;
      line-height: 25px;
      text-align: center;
    }
  }
}
</style>
