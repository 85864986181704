

































import { defineComponent, Ref, computed, watch } from '@vue/composition-api';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { ACL } from '../../../modules/acl/acl-service';
import { SitCenterDetails, SitCenterChartOptions, SitCenterTransportsDetails, IRouteType } from '../../../types';
import Popup from '@/components/shared/popup.vue';
import VueApexCharts from 'vue-apexcharts';
import { BasicSelect } from 'vue-search-select';

const sitCenterHelper = createNamespacedHelpers('layers/sitCenter');
const municipalitiesHelper = createNamespacedHelpers('municipalities');
const routeTypeHelper = createNamespacedHelpers('route-type');

export default defineComponent({
  components: { Popup, apexchart: VueApexCharts, BasicSelect },
  setup() {
    const { popupOpen, popupDetails, popupType, popupRouteType, routesDetails, transportsDetails, charts, emptyDetails }: {
      popupOpen: Ref<boolean>,
      popupDetails: Ref<string>,
      popupType: Ref<string>,
      popupRouteType: Ref<number>,
      routesDetails: Ref<Array<SitCenterDetails>>,
      transportsDetails: Ref<Array<SitCenterTransportsDetails>>,
      charts: Ref<Array<SitCenterChartOptions>>,
      emptyDetails: Ref<boolean>,
    } = sitCenterHelper.useGetters([
      'popupOpen',
      'popupDetails',
      'popupType',
      'popupRouteType',
      'routesDetails',
      'transportsDetails',
      'charts',
      'emptyDetails',
    ]) as any;

    const { closeChartsPopup }: {
      closeChartsPopup: () => Promise<void>,
    } = sitCenterHelper.useActions(['closeChartsPopup']) as any;

    const { current } = municipalitiesHelper.useGetters(['current']);

    const { routeTypes }: { routeTypes: Ref<Array<IRouteType>>, } = routeTypeHelper.useGetters(['routeTypes']) as any;

    const { loadRoutes, loadTransports, clearRoutesDetails, clearTransportsDetails, setCharts, setRouteType }: {
      loadRoutes: ({ municipalityId, bg }: { municipalityId: number, bg?: boolean }) => Promise<void>,
      loadTransports: ({ municipalityId, bg }: { municipalityId: number, bg?: boolean }) => Promise<void>,
      clearRoutesDetails: () => Promise<void>,
      clearTransportsDetails: () => Promise<void>,
      setCharts: (charts: Array<SitCenterChartOptions>) => Promise<void>,
      setRouteType: (routeType: number) => Promise<void>,
    } = sitCenterHelper.useActions([
      'loadRoutes',
      'loadTransports',
      'clearRoutesDetails',
      'clearTransportsDetails',
      'setCharts',
      'setRouteType',
    ]) as any;

    const title = computed(() => {
      if (popupType.value === 'routes') {
        return popupDetails.value === 'actual' ? 'Все маршруты общественного транспорта' : 'Маршруты общественного транспорта с назначениями';
      } else {
        return popupDetails.value === 'inline' ? 'Общественный транспорт на линии' : 'Общественный транспорт назначенный';
      }
    });

    const acl = computed(() => {
      return ACL.can('dashboard.routes');
    });

    const routeType = computed(() => {
      if (routeTypes) {
        const current = routeTypes.value.filter((entry: IRouteType) => entry.id === popupRouteType.value)?.shift();

        return {
          value: popupRouteType.value,
          text: current?.name,
        };
      }

      return {
        value: 0,
        text: '',
      };
    });

    const showSelect = computed(() => {
      return popupType.value === 'transports';
    });

    const options = computed(() => {
      if (transportsDetails && routeTypes) {
        const ids = transportsDetails.value.map((entry: SitCenterTransportsDetails) => entry.route_type_id);

        return routeTypes.value
          .filter((type: IRouteType) => ids.indexOf(type.id) !== -1)
          .map((type: IRouteType) => ({
            value: type.id,
            text: type.name,
          }));
      }

      return [];
    });

    const select = (data: { value: number, text: string, }) => {
      setRouteType(data.value);
    };

    if (popupType.value === 'routes') {
      clearRoutesDetails();
    } else {
      clearTransportsDetails();
    }

    const fillCharts = (values: Array<SitCenterDetails>) => {
      const data: Array<SitCenterChartOptions> = [];

      values.forEach(row => {
        const series: Array<number> = [];
        const labels: Array<string> = [];

        row.values.forEach(value => {
          labels.push(value.name);
          series.push(value.count);
        });

        if (series.length) {
          data.push({
            name: row.name,
            series,
            options: {
              chart: {
                type: 'donut',
                width: 300,
              },
              labels,
              legend: {
                show: false,
              },
            },
          });
        }
      });

      setCharts(data);
    };

    watch(popupRouteType, () => {
      if (popupType.value === 'transports' && popupRouteType.value > 0) {
        if (transportsDetails.value && transportsDetails.value instanceof Array) {
          transportsDetails.value.forEach(entry => {
            if (entry.route_type_id === popupRouteType.value) {
              fillCharts(entry.data);
            }
          });
        }
      }
    });

    watch(routesDetails, () => {
      if (routesDetails.value) {
        fillCharts(routesDetails.value);
      }
    });

    watch(transportsDetails, () => {
      if (transportsDetails.value && transportsDetails.value instanceof Array) {
        transportsDetails.value.forEach(entry => {
          if (entry.route_type_id === popupRouteType.value) {
            fillCharts(entry.data);
          }
        });
      }
    });

    const init = (clear = true) => {
      if (!acl.value) {
        return;
      }

      if (popupType.value === 'routes') {
        if (clear) {
          clearRoutesDetails();
        }

        loadRoutes({ municipalityId: current.value.id, bg: false });
      } else {
        if (clear) {
          clearTransportsDetails();
        }

        loadTransports({ municipalityId: current.value.id, bg: false });
      }
    };

    watch(popupOpen, () => {
      if (popupOpen.value) {
        init();
      }
    });

    return {
      title,
      acl,
      emptyDetails,
      popupOpen,
      popupDetails,
      popupType,
      charts,
      routeType,
      options,
      showSelect,
      select,
      onClose() {
        closeChartsPopup();
      },
    };
  },
});
