<template>
  <div class="layers">
    <div class="layers__wrapper">
      <div v-if="showLayers" class="layers__control" @click="showLayers = !showLayers">
        <img src="/assets/images/app/close.svg" title="Закрыть">
      </div>
      <div v-else class="layers__control" @click="showLayers = !showLayers" style="padding: 16px 15px;">
        <img src="/assets/images/app/layers.svg" title="Слои">
      </div>
      <div class="layers__list" v-show="showLayers">
        <div class="layers__list-header">
          Выбор слоёв
        </div>
        <ul class="layers__list-items">
          <layer-params
            v-for="(item, index) in renderedLayers"
            :key="index"
            :layer="item"
            :layer-index="index"
            @change="toggleLayer"
          ></layer-params>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ACL } from '@/modules/acl/acl-service';
import { MapService } from '@/services/map-service';
import { LayersService } from '@/services/layers-service';
import UserService from '@/services/user-service';
import LayerParams from '@/components/map/layer-params';
import { weatherSourceTypes } from '@/services/layers/weather-layer';
import roadTypes from '../../constants/roadTypes';
import { PASSENGER_TYPE_GROUP } from '../../constants/RouteTypeGroups';
import { LOCALITY_TYPES } from '../../constants/Global';

export default {
  name: 'layers',
  components: { LayerParams },
  data: () => ({
    showLayers: false,
    user: UserService.instance.user,
    layers: [
      {
        hidden: false,
        checked: true,
        title: 'Транспорт',
        type: 'transport',
        permission: 'transport.list',
        params: {
          types: [],
        },
      },
      {
        hidden: false,
        checked: false,
        title: 'История движения ТС',
        type: 'historyTransport',
        permission: 'history.search',
      },
      {
        hidden: false,
        checked: false,
        title: 'Маршруты',
        type: 'route',
        permission: 'route.list',
        params: {
          types: [],
          groups: [],
        },
      },
      {
        hidden: false,
        checked: false,
        title: 'Остановки',
        type: 'busStop',
        permission: 'bus_stop.list',
        params: {
          types: [],
          groups: [],
        },
      },
      {
        hidden: false,
        checked: false,
        title: 'Дороги',
        type: 'road',
        permission: 'road.list',
        params: {
          types: [],
          groups: [],
        },
      },
      { hidden: false, checked: false, title: 'Аварийность', type: 'accident', permission: 'accident.list' },
      { hidden: false, checked: false, title: 'Фото-видео фиксация', type: 'detector', permission: 'detector.list' },
      { hidden: false, checked: false, title: 'Видеонаблюдение', type: 'video', permission: 'videocam.list' },
      { hidden: true, checked: false, title: 'Регулируемые перекрестки', type: 'trafficLight', permission: 'traffic_light.list' },
      // { hidden: false, checked: false, title: 'Весовой контроль', type: '' },
      { hidden: false, checked: false, title: 'Пробки', type: 'trafficJam', permission: 'service.traffic_jams' },
      { hidden: false, checked: false, title: 'Ремонт дорог', type: 'repairRoad', permission: 'repair_road.list' },
      { hidden: true, checked: false, title: 'Спецстоянки', type: 'parkingFine', permission: 'impound.list' },
      { hidden: true, checked: false, title: 'Парковки', type: 'parking', permission: 'parking.list' },
      {
        hidden: false,
        checked: false,
        title: 'Погода',
        type: 'weather',
        permission: 'service.weather',
        params: {
          types: [],
        },
      },
      {
        hidden: false,
        checked: false,
        title: 'Населённые пункты',
        type: 'locality',
        permission: 'locality.list',
        params: {
          types: [],
          groups: [],
        },
      },
      {
        hidden: false,
        checked: false,
        title: 'Инфраструктура',
        type: 'custom',
        permission: 'layer.list',
        params: {
          types: [],
          groups: [],
        },
      },
      {
        hidden: false,
        checked: false,
        title: 'Дома',
        type: 'house',
        permission: 'house.list',
      },
      {
        hidden: false,
        checked: false,
        title: 'Площадки ТКО',
        type: 'trashArea',
        permission: 'trash_area.list',
      },
      // новый слой добавлять перед данным комментарием
      { hidden: true, checked: false, title: 'Анализ задублированности', type: 'analysisDouble', permission: 'route.list' },
      { hidden: true, checked: false, title: 'Анализ транспортной доступности', type: 'analysis', permission: 'route_analysis.form1' },
      { hidden: true, checked: false, title: 'Анализ средней скорости', type: 'analysisAverage', permission: 'report_avg_speed.form1' },
      { hidden: true, checked: false, title: 'Ситуационный центр', type: 'sitCenter', group: 'dashboard' },
      { hidden: true, checked: true, title: 'Организаторы деятельности', type: 'municipality', permission: 'municipality.list' },
    ],
    layerService: null,
    subscription: null,
    weatherTimer: null,
    unsubscribe: null,
    isDestroying: false,
  }),
  methods: {
    ...mapActions({
      openDetectorLayerSidebar: 'transports/openDetectorLayerSidebar',
    }),
    toggleHistoryTransportLayer() {
      const historyTransportLayerIndex = this.findLayerIndex('historyTransport');
      const transportLayerIndex = this.findLayerIndex('transport');
      if (this.layers[transportLayerIndex].checked) {
        this.layers[historyTransportLayerIndex].hidden = false;
      } else {
        this.layerService.destroySingle(this.layers[historyTransportLayerIndex]);
        this.layers[historyTransportLayerIndex].hidden = true;
        this.layers[historyTransportLayerIndex].checked = false;
      }
    },
    toggleLayer(index, layer) {
      if (layer) {
        this.layers = this.layers.map(l => l.type === layer.type ? layer : l);
      }

      if (!layer) {
        this.layerService.render(this.activeLayers);
      } else if (layer.checked) {
        this.layerService.renderSingle(layer);
      } else {
        this.layerService.destroySingle(layer);
      }
      if (layer?.type === 'transport') {
        this.toggleHistoryTransportLayer();
      }
    },
    renderLayers() {
      this.layerService.init();
      this.layerService.render(this.activeLayers);
    },
    selectLayer(index, checkStatus) {
      this.layers[index].checked = checkStatus;
      this.toggleLayer(index);
    },
    findLayerIndex(type) {
      return this.layers.findIndex((layer) => {
        return layer.type === type;
      });
    },
    updateLayers(layers) {
      layers.forEach(layer => {
        this.toggleLayer(undefined, layer);
      });
    },
    changeQuery(query) {
      if (this.$route.fullPath.indexOf('/map/analysis/') !== -1) {
        return;
      }

      if (!query['from-analysis']) {
        this.layers.forEach(item => {
          if (!item.hidden) {
            item.checked = false;

            if (item.params && item.params.types) {
              item.params.types.forEach(elem => {
                elem.checked = false;
              });
            }
          }
        });
      }

      this.toggleLayer();

      if (Object.keys(query).length === 0) {
        this.layers[this.findLayerIndex('transport')].checked = true;
        this.layers[this.findLayerIndex('transport')].params.types.forEach(type => {
          type.checked = true;
        });
      }

      if (query.routes === 'on') {
        this.layers[this.findLayerIndex('route')].checked = true;
        this.layers[this.findLayerIndex('route')].params.types.forEach(type => {
          type.checked = true;
        });
      }

      if (query.transport) {
        const ids = query.transport.split(',').map(id => Number(id));
        this.layers = this.layers.map((layer, index) => index === 0 ? {
          ...layer,
          checked: true,
        } : layer);
        this.layers[this.findLayerIndex('historyTransport')].hidden = false;
        this.routeTypes.forEach((item, index) => {
          if (ids.indexOf(item.id) !== -1) {
            this.layers[this.findLayerIndex('transport')].params.types[index].checked = true;
          }
        });
      }

      if (query?.historyTransport) {
        this.layers[this.findLayerIndex('historyTransport')].checked = true;
      }

      this.toggleLayer();
    },
    checkPermission(layer) {
      if (layer.permission) {
        if (!ACL.can(layer.permission)) {
          return false;
        }
      } else if (layer.group) {
        if (!ACL.canGroup(layer.group)) {
          return false;
        }
      }
      return true;
    },
    openLayer(type, checked = true, toggle = true) {
      const layer = this.layers.find(layer => layer.type === type);

      if (!layer || layer.checked === checked) {
        return;
      }

      this.routeTypes.forEach((item, i) => {
        if (layer.params && layer.params.types[i]) {
          layer.params.types[i].checked = checked;
        }
      });
      layer.checked = checked;
      if (toggle) {
        this.toggleLayer();
      }
    },
    openDetectors(transportNumber) {
      this.openLayer('detector');

      if (transportNumber) {
        this.openDetectorLayerSidebar(transportNumber);
      }
    },
    openTransport() {
      this.openLayer('transport');
    },
    initCustomLayer(layers) {
      layers
        .forEach(type => {
          this.openLayer(type, true, false);
        });
      this.toggleLayer();
    },
    openLocality() {
      const localityLayerIndex = this.findLayerIndex('locality');

      this.routeTypes.forEach((item, i) => {
        if (this.layers[localityLayerIndex].params && this.layers[localityLayerIndex].params.types[i]) {
          this.layers[localityLayerIndex].params.types[i].checked = false;
        }
      });

      this.layers[localityLayerIndex].params.types[0].checked = true;
      this.layers[localityLayerIndex].checked = true;

      this.toggleLayer(undefined, this.layers[localityLayerIndex]);
    },
    disableLayers(layers, toggle = false) {
      layers.forEach(type => {
        this.openLayer(type, false, false);
      });

      if (toggle && layers.length > 0) {
        this.toggleLayer();
      }
      if (layers.includes('historyTransport')) {
        this.toggleHistoryTransportLayer();
      }
    },
  },
  computed: {
    ...mapGetters({
      routeTypes: 'route-type/routeTypes',
      municipalities: 'municipalities/municipalities',
      customLayers: 'layer/layers',
      openLayers: 'map/layers',
      layerParams: 'map/layerParams',
    }),
    renderedLayers() {
      return this.layers
        .filter(layer => !layer.hidden)
        .filter(layer => this.checkPermission(layer));
    },
    activeLayers() {
      return this.layers.filter(layer => layer.checked);
    },
  },
  watch: {
    '$route'(to, from) {
      this.changeQuery(to.query);
    },
  },
  created() {
    if (Object.keys(this.$route.query).length || this.openLayers.indexOf('transport') === -1) {
      this.layers[this.findLayerIndex('transport')].checked = false;
    }

    this.layers[this.findLayerIndex('transport')].params.types = this.routeTypes.map(type => ({
      checked: this.layers[this.findLayerIndex('transport')].checked,
      id: type.id,
      title: type.name,
    }));

    const checkedRouteLayer = this.layers[this.findLayerIndex('route')].checked;

    const routeTypesByGroup = this.routeTypes
      .filter(type => type.type_group === PASSENGER_TYPE_GROUP)
      .map(type => ({
        id: type.id,
        title: type.name,
      }));
    this.layers[this.findLayerIndex('route')].params.groups.push({
      name: 'Тип ТС',
      types: routeTypesByGroup.map(type => ({
        ...type,
        checked: checkedRouteLayer,
      })),
    });

    const busStopLayerIndex = this.findLayerIndex('busStop');
    const checkedBusStopLayer = this.layers[busStopLayerIndex].checked;

    this.layers[busStopLayerIndex].params.groups.push({
      name: 'По виду',
      types: routeTypesByGroup.map(type => ({
        ...type,
        checked: checkedBusStopLayer,
      })),
    });

    this.layers[busStopLayerIndex].params.groups.push({
      name: 'По типу',
      types: [{
        id: 0,
        title: 'АВ, АС, КП',
        checked: checkedBusStopLayer,
      }],
    });

    this.layers[busStopLayerIndex].params.groups.push({
      name: 'По владельцам',
      types: [
        {
          id: 0,
          title: 'Федеральные',
          checked: checkedBusStopLayer,
        },
        {
          id: 1,
          title: 'Региональные',
          checked: checkedBusStopLayer,
        },
        {
          id: 2,
          title: 'Муниципальные',
          checked: checkedBusStopLayer,
        },
      ],
    });

    const roadLayerIndex = this.findLayerIndex('road');
    const checkedRoadLayer = this.layers[roadLayerIndex].checked;

    this.layers[roadLayerIndex].params.groups.push({
      name: 'По типу',
      types: Object.values(roadTypes).map(type => ({
        id: type.id,
        title: type.name,
        checked: checkedBusStopLayer,
      })),
    });

    if (ACL.can('transport_road.list')) {
      this.layers[roadLayerIndex].params.groups.push({
        name: 'Подслой',
        types: [
          {
            checked: false,
            id: 0,
            title: 'История движения ДТ',
          },
        ],
      });
    }

    const weatherLayerIndex = this.findLayerIndex('weather');

    this.layers[weatherLayerIndex].params.types.push({
      checked: this.layers[weatherLayerIndex].checked,
      id: weatherSourceTypes.YANDEX,
      title: 'Яндекс Погода',
    },
    {
      checked: this.layers[weatherLayerIndex].checked,
      id: weatherSourceTypes.METEO,
      title: 'Метеостанции',
    },
    {
      checked: this.layers[weatherLayerIndex].checked,
      id: weatherSourceTypes.AUTO_METEO,
      title: 'Автодорожные метеопосты',
    });

    const localityLayerIndex = this.findLayerIndex('locality');
    this.layers[localityLayerIndex].params.types = LOCALITY_TYPES.map(type => ({
      checked: this.layers[localityLayerIndex].checked,
      id: type.id,
      title: type.name,
    }));

    const customLayerIndex = this.findLayerIndex('custom');

    this.customLayers.forEach(layer => {
      this.layers[customLayerIndex].params.types.push({
        checked: this.layers[customLayerIndex].checked,
        id: layer.id,
        title: layer.name,
      });
    });

    this.layerService = LayersService.instance;
    this.subscription = this.renderLayers.bind(this);

    this.weatherTimer = setInterval(() => {
      this.activeLayers.forEach(layer => {
        if (layer.type === 'weather') {
          this.layerService._weatherLayers.reRender(this.layers[weatherLayerIndex].params, true);
        }
      });
    }, 600000);

    MapService.instance.subscribe('map-initialized', this.subscription);

    if (Object.keys(this.$route.query).length) {
      setTimeout(() => this.changeQuery(this.$route.query), 100);
    }

    this.layers.forEach(layer => {
      const fullLayer = this.layerParams.find(l => l.type === layer.type);
      if (!this.checkPermission(layer)) {
        layer.checked = false;
        if (layer.params && layer.params.types) {
          layer.params.types.forEach(type => {
            type.checked = false;
          });
        }
      } else if (this.openLayers.indexOf(layer.type) !== -1 && fullLayer) {
        layer.checked = true;
        if (layer.params && layer.params.types) {
          layer.params.types.forEach((type, i) => {
            type.checked = !!fullLayer.params.types[i].checked;
          });
        }
        if (layer.params && layer.params.groups) {
          layer.params.groups.forEach((group, i) => {
            group.checked = !!fullLayer.params.groups[i].checked;
            group.types.forEach((type, j) => {
              type.checked = !!fullLayer.params.groups[i].types[j].checked;
            });
          });
        }
      }
    });

    this.unsubscribe = this.$store.subscribeAction(action => {
      if (this.isDestroying) {
        return;
      }

      if (action.type === 'transports/showDetectorLayer') {
        this.openDetectors(action.payload);
      }

      if (action.type === 'transports/openTransportLayer') {
        this.openTransport(action.payload);
      }

      if (action.type === 'layer/openLayers') {
        this.initCustomLayer(action.payload);
      }

      if (action.type === 'layer/disableLayers') {
        const { layers, toggle } = action.payload;
        this.disableLayers(layers, toggle);
      }

      if (action.type === 'layer/openLocality') {
        this.openLocality();
      }

      if (action.type === 'layer/updateLayers') {
        this.updateLayers(action.payload);
      }
    });
  },
  beforeDestroy() {
    this.isDestroying = true;
    MapService.instance.unsubscribe('map-initialized', this.subscription);
    this.layerService.destroy();
    clearInterval(this.weatherTimer);

    if (this.unsubscribe) {
      this.unsubscribe();
    }
  },
};
</script>

<style lang="scss" scoped>
  .layers {
    position: absolute;
    top: 30px;
    right: 30px;
    z-index: 1000;

    &__wrapper {
      position: relative;
    }

    &__control {
      font-size: 0;
      width: 50px;
      height: 50px;
      background-color: var(--main-color);
      border-radius: 3px;
      box-shadow: var(--base-shadow);
      padding: 18px;
      cursor: pointer;
    }

    &__list {
      position: absolute;
      top: 0;
      right: 65px;
      width: 250px;
      background: var(--main-color);
      color: #FFFFFF;
      margin-bottom: 0;
      border-radius: 3px;

      &-header {
        padding: 15px;
        border-bottom: 1px solid rgba(255, 255, 255, .25);
        text-transform: uppercase;
        font-weight: 500;
        font-size: 16px;
      }

      &-items {
        padding: 15px;
        margin-bottom: 0;

        &-item {
          list-style: none;
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }

          input[type='checkbox'] {
            display: none;

            &:checked {
              +label {
                &:before {
                  background: #FFFFFF;
                }
              }
            }
          }

          label {
            cursor: pointer;
            margin-bottom: 0;
            position: relative;
            padding-left: 18px;

            &:before {
              position: absolute;
              top: 4px;
              left: 0;
              content: "";
              width: 10px;
              height: 10px;
              outline: 1px solid #FFFFFF;
              border: 1px solid var(--main-color);
            }
          }
        }
      }
    }
  }
</style>
