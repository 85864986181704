













































import { computed, defineComponent, Ref } from '@vue/composition-api';
import { IAnalysisAverageForm2ReponseItemRow, IAnalysisAverageForm2TableRow } from '../../types';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { LayersService } from '../../services/layers-service';
import moment from 'moment';

const analysisHelper = createNamespacedHelpers('layers/analysisAverage');

export default defineComponent({
  name: 'AnalysisAverageForm2Table',
  props: ['openSidebar'],
  setup() {
    const keys: Array<keyof IAnalysisAverageForm2TableRow> = ['count_ts', 'length', 'avg_time', 'avg_speed', 'traffic_avg_time', 'traffic_avg_speed', 'min_complete_time', 'max_speed', 'max_time', 'traffic_road_names'];
    const headers: {
      [key in keyof IAnalysisAverageForm2TableRow]?: string
    } = {
      count_ts: 'Количество транспорта проехавшего на маршруту',
      length: 'Протяжённость маршрута, км',
      avg_time: 'Среднее время прохождения маршрута',
      avg_speed: 'Средняя скорость прохождения маршрута, км/ч',
      traffic_avg_time: 'Среднее время в пробках',
      traffic_avg_speed: 'Средняя скорость в пробке, км/ч',
      min_complete_time: 'Минимальное время прохождения маршрута',
      max_speed: 'Максимальная скорость на маршруте, км/ч',
      max_time: 'Максимальное время прохождения маршрута',
      traffic_road_names: 'Места простоев на маршруте',
    };

    const { selectedForm2 }: {
      selectedForm2: Ref<Array<IAnalysisAverageForm2ReponseItemRow> | null>,
    } = analysisHelper.useGetters(['selectedForm2']) as any;

    const items = computed(() => {
      const answer: Array<IAnalysisAverageForm2TableRow> = [];

      if (selectedForm2.value) {
        selectedForm2.value.forEach(r => {
          answer.push({
            count_ts: Number(r.count_ts).toFixed(0),
            length: `${r.name} — ${Number(r.length ?? 0).toFixed(2)}`,
            avg_time: r.avg_time,
            avg_speed: Number(r.avg_speed).toFixed(2),
            traffic_avg_time: r.traffic_time,
            traffic_avg_speed: Number(r.avg_traffic_speed).toFixed(2),
            min_complete_time: r.min_time,
            max_speed: Number(r.max_speed).toFixed(2),
            max_time: r.max_time,
            traffic_road_names: r.traffic_road_names,
          });
        });
      }

      return answer;
    });

    const isEnabled = computed(() => items.value.length > 0);

    const currentDate = computed(() => {
      if (selectedForm2.value && selectedForm2.value.length > 0 && selectedForm2.value[0].hour) {
        return `Период: ${moment(selectedForm2.value[0].hour).locale('ru').format('DD MMMM HH:00')}`;
      } else if (selectedForm2.value && selectedForm2.value.length > 0) {
        const f = LayersService.instance._analysisAverageLayers.filter;
        return `Период: ${moment(f.general.dates[0]).locale('ru').format('DD MMMM HH:00')} — ${moment(f.general.dates[1]).locale('ru').format('DD MMMM HH:00')}`;
      }

      return '';
    });

    const currentDateColspan = computed(() => {
      return Object.keys(headers).length;
    });

    return {
      selectedForm2,
      headers,
      items,
      isEnabled,
      keys,
      currentDate,
      currentDateColspan,
    };
  },
});
