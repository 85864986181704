








































import Vue from 'vue';
import { computed, defineComponent, onBeforeUnmount, Ref, watch } from '@vue/composition-api';
import MTSTypes from '../../../constants/MTSTypes';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { IRouteType, SitCenterRoute } from '../../../types';
import { ACL } from '../../../modules/acl/acl-service';
import ChartsPopup from './chartsPopup.vue';

const sitCenterHelper = createNamespacedHelpers('layers/sitCenter');
const municipalitiesHelper = createNamespacedHelpers('municipalities');
const routeTypeHelper = createNamespacedHelpers('route-type');

export default defineComponent({
  setup() {
    const { routeTypes }: {
      routeTypes: Ref<Array<IRouteType>>,
    } = routeTypeHelper.useGetters(['routeTypes']) as any;
    const { loadRoutes, clearRoutes }: {
      loadRoutes: ({ municipalityId, bg }: { municipalityId: number, bg?: boolean }) => Promise<void>,
      clearRoutes: () => Promise<void>,
    } = sitCenterHelper.useActions(['loadRoutes', 'clearRoutes']) as any;
    const { routes }: {
      routes: Ref<Array<SitCenterRoute>>,
    } = sitCenterHelper.useGetters(['routes']) as any;
    const { current } = municipalitiesHelper.useGetters(['current']);
    const { openChartsPopup }: {
      openChartsPopup: (
        { type, details } : { type: string, details: string }
      ) => Promise<void>,
    } = sitCenterHelper.useActions(['openChartsPopup']) as any;

    const init = (clear = true) => {
      if (!acl.value) {
        return;
      }

      if (clear) {
        clearRoutes();
      }

      loadRoutes({ municipalityId: current.value.id });
    };

    const total = computed(() => {
      return routes.value.reduce((acc, current) => {
        return {
          assigned: acc.assigned + current.assigned_count,
          actual: acc.actual + current.actual_count,
        };
      }, {
        assigned: 0,
        actual: 0,
      });
    });

    const percentage = computed(() => {
      return total.value.actual > 0 ? Math.floor((total.value.assigned / total.value.actual) * 100) : 100;
    });

    const acl = computed(() => {
      return ACL.can('dashboard.routes');
    });

    watch(current, () => init());
    init();

    const updater = window.setInterval(() => init(false), 1000 * 60);

    onBeforeUnmount(() => {
      window.clearInterval(updater);
    });

    return {
      details(details: string) {
        openChartsPopup({ type: 'routes', details });
      },
      getIcon(id: number) {
        const type = routeTypes.value.find(t => Number(t.id) === Number(id));

        if (type) {
          return type.image_url;
        }

        return `/assets/icons/bus.svg`;
      },
      getAlt(id: number) {
        const type = routeTypes.value.find(t => Number(t.id) === Number(id));

        if (type) {
          return type.name;
        }

        return '';
      },
      routes,
      total,
      percentage,
      current,
      acl,
    };
  },
});
