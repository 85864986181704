












































































































import { computed, defineComponent, ref, Ref } from '@vue/composition-api';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { IRouteConverge, IRouteConvergeRow } from '../../types';
import ColorPicker from '../../components/shared/color-picker.vue';
import { LayersService } from '../../services/layers-service';

const routeHelper = createNamespacedHelpers('route');

export default defineComponent({
  name: 'RouteConvergeTable',
  props: ['openSidebar'],
  components: {
    ColorPicker,
  },
  setup() {
    const availableSorts = ['route', 'transporter', 'distance', 'percent'];
    const sortKey = ref('route');
    const sortOrder = ref(true);
    const keys: Array<keyof IRouteConvergeRow> = ['route', 'transporter', 'day1', 'day2', 'day3', 'day4', 'day5', 'day6', 'day7', 'distance', 'percent', 'color'];
    const headers: {
      row1: {
        [key in keyof IRouteConvergeRow | 'days']?: string
      },
      row2: {
        [key in keyof IRouteConvergeRow]?: string
      },
    } = {
      row1: {
        route: 'Маршруты',
        transporter: 'Перевозчик',
        days: 'Кол-во рейсов',
        distance: 'Протяж.',
        percent: 'Доля дубл.',
        color: '',
      },
      row2: {
        day1: 'Пн',
        day2: 'Вт',
        day3: 'Ср',
        day4: 'Чт',
        day5: 'Пт',
        day6: 'Сб',
        day7: 'Вс',
      },
    };
    const { converge, duplicationRouteGroupId }: {
      converge: Ref<Array<IRouteConverge>>,
      duplicationRouteGroupId: Ref<number | null>,
    } = routeHelper.useGetters(['converge', 'duplicationRouteGroupId']) as any;
    const items = computed(() => {
      const answer: Array<IRouteConvergeRow> = [];

      converge.value
        .filter(conv => conv.active)
        .forEach(conv => {
          conv.route_groups.forEach(group => {
            group.transporters.forEach(item => {
              const days = item.days.reduce((acc, value) => {
                return {
                  ...acc,
                  [`day${value.day}`]: value.count_trips,
                };
              }, {});
              answer.push({
                id: conv.id,
                route: conv.name,
                transporter: item.name,
                ...days,
                distance: Number(group.length.toFixed(0)),
                percent: group.duplication_percent,
                color: conv.color,
                groupId: group.id,
              });
            });

            if (group.transporters.length === 0) {
              answer.push({
                id: conv.id,
                route: conv.name,
                transporter: '—',
                day1: '—',
                day2: '—',
                day3: '—',
                day4: '—',
                day5: '—',
                day6: '—',
                day7: '—',
                distance: Number(group.length.toFixed(0)),
                percent: group.duplication_percent,
                color: conv.color,
                groupId: group.id,
              });
            }
          });
        });

      return answer.sort((a: any, b: any) => {
        const order = sortOrder.value ? 1 : -1;
        return a[sortKey.value] > b[sortKey.value] ? order : -order;
      });
    });
    const highlightedRoute: Ref<number | null> = computed(() => {
      return LayersService.instance._routeLayers.highlightedRoute;
    });

    return {
      converge,
      headers,
      items,
      keys,
      availableSorts,
      sortOrder,
      sortKey,
      duplicationRouteGroupId,
      highlightedRoute,
      changeColor({ id, color }: {
        id: number,
        color: string,
      }) {
        LayersService.instance._routeLayers.changeAnalysisRouteColor(id, color);
      },
      sort(key: string) {
        if (sortKey.value === key) {
          sortOrder.value = !sortOrder.value;
        } else if (availableSorts.indexOf(key) !== -1) {
          sortKey.value = key;
          sortOrder.value = true;
        }
      },
      highlightRoute(id: number) {
        LayersService.instance._routeLayers.highlightAnalysisRoute(id);
      },
      convergeByGroup(groupId: number) {
        LayersService.instance._routeLayers.converge(groupId);
      },
    };
  },
});
